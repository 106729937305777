import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  concat,
  HttpLink,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from '@apollo/client';

import Auth from './components/auth';
import EnvBanner from './components/env-banner';
import Header from './components/header';
import SigninPage from './containers/signin';
import DashboardContainer from './containers/dashboard';
import ChangePassword from './containers/change-password';

// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from './theme';

import './styles/App.scss';
import './index.scss';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-api-key': process.env.REACT_APP_API_KEY,
      authorization: localStorage.getItem('token') || ''
    }
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

_.get(console, 'log')('Amplify Config:', {
  Auth: {
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION
  }
});

Amplify.configure({
  Auth: {
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION
  }
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Auth>
          <ApolloProvider client={client}>
            <Router>
              <EnvBanner />
              <Header />
              <Switch>
                <Route
                  exact
                  path="/change-password"
                  render={(props) => <ChangePassword {...props} />}
                />
                <Route
                  exact
                  path="/sign-in"
                  render={(props) => <SigninPage {...props} />}
                />
                <Route path="/" component={DashboardContainer} />
              </Switch>
            </Router>
          </ApolloProvider>
        </Auth>
      </ThemeProvider>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
